<template>
  <div>
    <h1>Collection Speaker !</h1>
    <Autosaveinfo v-if="slct_value != ''" :data="autoSave" @save="update_spk()" />
    <section id="collect">
      <section class="inline" id="select">
        <div>
          <select
            name=""
            v-model="slct_value"
            @change="onChangeSelect($event)"
            placeholder="Speaker"
          >
            <option value="" selected disabled hidden>Choisir un Speaker</option>
            <option v-for="spk in speakers" :key="spk._id" :value="spk._id">
              {{ spk.spk_firstname }} - {{ spk.spk_lastname }}
            </option>
          </select>
        </div>
        <div v-if="slct_value == ''">OU</div>
        <div class="inline">
          <button v-if="slct_value == ''" @click="add_spk">Ajouter</button>
          <button v-if="slct_value != ''" @click="reset_slct">Annuler</button>
          <button v-if="slct_value != ''" @click="remove">Supprimer</button>
        </div>
      </section>
      <div></div>
      <section class="inline">
        <section id="block_img">
          <div :class="{ errorImg: errors.img.color }">
            <EditableImage
              @change_image="change_image"
              :src_default="'default_speaker'"
              :src_img="temp.spk_thumbnail"
              :key="temp"
              :editable="true"
            />
          </div>
        </section>
        <section id="block_field">
          <section>
            <div class="inline">
              <div class="subBlock_field">
                <div class="inline">
                  <label :class="{ error: errors.spk_lastname.color }" for=""
                    >Nom :</label
                  >
                  <input v-model="temp.spk_lastname" type="text" />
                </div>
                <span v-if="errors.spk_lastname.msg" class="error"
                  >Ne doit contenir que des lettres</span
                >
              </div>

              <div class="subBlock_field">
                <div class="inline">
                  <label :class="{ error: errors.spk_firstname.color }" for=""
                    >Prénom :</label
                  >
                  <input v-model="temp.spk_firstname" type="text" />
                </div>
                <span v-if="errors.spk_firstname.msg" class="error"
                  >Ne doit contenir que des lettres</span
                >
              </div>
            </div>
            <div></div>
            <div class="inline">
              <div class="subBlock_field">
                <div class="inline">
                  <label :class="{ error: errors.spk_email.color }" for=""
                    >Email :</label
                  >
                  <input v-model="temp.spk_email" type="text" />
                </div>
                <span v-if="errors.spk_email.msg" class="error">
                  Format d'email invalid
                </span>
              </div>

              <div class="subBlock_field">
                <div class="inline">
                  <label for="">Type :</label>
                  <select class="select" name="" id="" v-model="temp.spk_type">
                    <option value="0">Personnalisé</option>
                    <option value="1">Mutualisé</option>
                  </select>
                </div>
              </div>
            </div>
            <div></div>
            <div class="inline">
              <div class="subBlock_field">
                <div class="inline">
                  <label :class="{ error: errors.spk_age.color }" for=""
                    >Age :</label
                  >
                  <input v-model="temp.spk_age" type="date" />
                </div>
              </div>

              <div class="subBlock_field">
                <div class="inline">
                  <label :class="{ error: errors.spk_gender.color }" for=""
                    >Sexe :</label
                  >
                  <select class="select" v-model="temp.spk_gender" name="" id="">
                    <option value="M">Homme</option>
                    <option value="F">Femme</option>
                  </select>
                </div>
              </div>
            </div>
          </section>
        </section>
        <section id="block_lang">
          <div class="inline">
            <label for="">Langues :</label>
            <input
              type="text"
              list="list_lang"
              @change="new_lang"
              placeholder="Ajouter une langue"
            />
          </div>

          <datalist id="list_lang">
            <option
              v-for="lang in collect_lang"
              :value="lang"
              :key="lang"
            ></option>
          </datalist>
          <div>
            <div v-if="temp.spk_lang" class="inline">
              <div v-for="lang in temp.spk_lang" :key="lang" class="inline_lang">
                <p>{{ lang }}</p>
                <button @click="del_lang(lang)">X</button>
              </div>
            </div>
          </div>
          <div>
            <label for="">LinkFrame : </label>
            <textarea v-model="temp.spk_iframe" />
          </div>
        </section>
      </section>
    </section>
  </div>
</template>



<script>
//API
import collect_api from "../../../api/collect";
import file_api from "../../../api/file";

//LIB
import verifGlobal from "../../../lib/verifGlobal";

import $ from "jquery";
//Components
import Autosaveinfo from "../../../components/Object/AutoSaveInfo";
import EditableImage from "../../../components/Object/EditableImage";
import snackbar from "../../../components/Object/snackbar";

export default {
  name: "Admin.speaker",
  components: { EditableImage, Autosaveinfo },
  data() {
    return {
      speakers: {},
      temp: {
        spk_type: 0,
        spk_firstname: "",
        spk_lastname: "",
        spk_email: "",
        spk_age: undefined,
        spk_gender: "",
        spk_lang: [],
        spk_thumbnail: undefined,
        spk_iframe: "",
      },
      slct_value: "",
      collect_lang: null,
      file: null,
      img_name: "",
      display: "",
      text: "",
      data_updated: false,
      errors: {
        spk_firstname: {},
        spk_lastname: {},
        spk_email: {},

        spk_age: {},
        spk_gender: {},

        img: {},
        global: false,
      },
      autoSave: {
        status: 1,
        lastKeyUp: Date,
        lastSave: "",
      },
    };
  },
  
  emits: ["change_image"],
  mounted() {
    this.collect_lang_default();

    collect_api
      .get("/speakers")
      .then((spks) => {
        this.speakers = spks;
      })
      .catch((err) => {
        snackbar.show({
          text: err.response.data,
          pos: "top-center",
          showAction: false,
          backgroundColor: snackbar.backgroundColor,
          duration: snackbar.duration,
          textColor: snackbar.colorLevel[0],
        });
      });
    $("input[type=text], textarea ").on("keyup", () => {
      this.autoSave.lastKeyUp = new Date();
      this.autoSave.status = 0;
      this.data_updated = true;
    });

    $(".select").on("change", () => {
      this.autoSave.lastKeyUp = new Date();
      this.autoSave.status = 0;
      this.data_updated = true;
    });

    //AutoSave Interval
    setInterval(() => {
      if (this.slct_value != "") {
        var currentTime = new Date();
        var delta = (currentTime - this.autoSave.lastKeyUp) / 1000;
        const saveDelay = 2;
        if (saveDelay == Math.round(delta)) {
          //SAVE
          console.debug("Saving data...");
          this.update_spk();
          this.autoSave.lastSave = (() => {
            var date = new Date();
            return date.getHours() + ":" + date.getMinutes();
          })();

          if (this.errors.global) {
            this.autoSave.status = 2;
          } else {
            this.autoSave.status = 1;
          }
        }
      }
    }, 1000);
  },
  updated: function () {
    //Calcule vdo duration estimated
    // this.vdo_duration = videoDuration.estimate(this.script);
  },
  watch: {
    temp: {
      handler(spk) {
        if (this.errors.global) {
          for (let field in spk) {
            if (
              !verifGlobal.isEmpty(spk[field]) &&
              this.errors[field] != undefined
            ) {
              this.errors[field].color = false;
            }
          }
        }
      },
      deep: true,
    },
    file: function (src) {
      if (this.errors.global) {
        if (!verifGlobal.isEmpty(src)) {
          this.errors.img.color = false;
        }
      }
    },

    "temp.spk_firstname": function (value) {
      //Vérifiacation Prénom alphabetic
      if (value != undefined && value != "") {
        let status = verifGlobal.notAlphabetical(value);
        this.errors.spk_firstname.msg = status;
        this.errors.spk_firstname.color = status;
      } else {
        this.errors.spk_firstname.msg = false;
        this.errors.spk_firstname.color = false;
      }
    },
    "temp.spk_lastname": function (value) {
      //Vérifiacation Nom alphabetic
      if (value != undefined && value != "") {
        let status = verifGlobal.notAlphabetical(value);
        this.errors.spk_lastname.msg = status;
        this.errors.spk_lastname.color = status;
        this.errors.global = status;
      } else {
        this.errors.spk_lastname.msg = false;
        this.errors.spk_lastname.color = false;
      }
    },
    "temp.spk_email": function (email) {
      //Vérifiacation syntax email
      if (email != undefined && email != "") {
        let status = !verifGlobal.syntaxEmail(email);
        this.errors.spk_email.msg = status;
        this.errors.spk_email.color = status;
        this.errors.global = !status;
      } else {
        this.errors.spk_email.msg = false;
        this.errors.spk_email.color = false;
      }
    },
  },
  methods: {
    onChangeSelect(event) {
      
      this.reset_slct();
      this.slct_value = event.target.value;
      if (event.target.value != "") {
        collect_api
          .get("/speakers/" + event.target.value)
          .then((spk) => {
            this.temp = spk;
            this.autoSave.lastSave = new Date(this.temp.updated_at);
          })
          .catch((err) => {
            snackbar.show({
              text: err.response.data,
              pos: "top-center",
              showAction: false,
              backgroundColor: snackbar.backgroundColor,
              duration: snackbar.duration,
              textColor: snackbar.colorLevel[0],
            });
          });

        this.collect_lang_default();
        if (this.temp.spk_lang) {
          for (let lang of this.temp.spk_lang) {
            let index = this.collect_lang.indexOf(lang);
            if (index > -1) {
              this.collect_lang.splice(index, 1);
            }
          }
        }
      }
    },
    update_spk() {
      if (!verifGlobal.isEmpty(this.file)) {
        file_api.put(this.file, this.temp.spk_thumbnail).then(() => {
          this.file = undefined;
        });
      }

      if (this.data_updated) {
        this.errors.global = false;

        for (let field in this.temp) {
          if (
            this.errors[field] != undefined &&
            verifGlobal.isEmpty(this.temp[field])
          ) {
            this.errors[field].color = true;
          }
        }

        for (let field in this.errors) {
          if (this.errors[field].color) {
            this.errors.global = true;
          }
        }

        if (!this.errors.global) {
          collect_api
            .update("speakers", this.temp)
            .then((res) => {
              let index = this.speakers.findIndex(
                (obj) => obj._id == this.temp._id
              );
              this.speakers[index] = this.temp;
              this.data_updated = false;
            
              snackbar.show({
                text: "Speaker correctement modifié",
                pos: "top-center",
                showAction: false,
                backgroundColor: snackbar.backgroundColor,
                duration: snackbar.duration,
                textColor: snackbar.colorLevel[2],
              });
            })
            .catch((err) => {
           
              snackbar.show({
                text: err.response.data,
                pos: "top-center",
                showAction: false,
                backgroundColor: snackbar.backgroundColor,
                duration: snackbar.duration,
                textColor: snackbar.colorLevel["error"],
              });
            });
        } else {
          snackbar.show({
            text: "Les champs en rouge sont indispensables !",
            pos: "top-center",
            showAction: false,
            backgroundColor: snackbar.backgroundColor,
            duration: snackbar.duration,
            textColor: snackbar.colorLevel[0],
          });
        }
      }
    },
    add_spk() {
      this.errors.global = false;
      for (let field in this.temp) {
        if (
          this.errors[field] != undefined &&
          verifGlobal.isEmpty(this.temp[field])
        ) {
          this.errors[field].color = true;
        }
      }

      if (verifGlobal.isEmpty(this.file)) {
        this.errors.img.color = true;
      }
      for (let field in this.errors) {
        if (this.errors[field].color) {
          this.errors.global = true;
        }
      }

      if (!this.errors.global) {
        file_api
          .post(this.file)
          .then((pic) => {
            this.temp.spk_thumbnail = pic._id;
            collect_api
              .add("speakers", this.temp)
              .then((res) => {
                snackbar.show({
                  text: "Speaker correctement ajouté",
                  pos: "top-center",
                  showAction: false,
                  backgroundColor: snackbar.backgroundColor,
                  duration: snackbar.duration,
                  textColor: snackbar.colorLevel[2],
                });
                this.speakers.push(res);
                this.reset_slct();
              })
              .catch((err) => {
                snackbar.show({
                  text: err.response.data,
                  pos: "top-center",
                  showAction: false,
                  backgroundColor: snackbar.backgroundColor,
                  duration: snackbar.duration,
                  textColor: snackbar.colorLevel[0],
                });
              });
          })
          .catch((err) => console.log(err));
      } else {
        snackbar.show({
          text: "Les champs en rouge sont indispensables !",
          pos: "top-center",
          showAction: false,
          backgroundColor: snackbar.backgroundColor,
          duration: snackbar.duration,
          textColor: snackbar.colorLevel[0],
        });
      }
    },
    remove() {
      collect_api
        .delete("speakers", this.temp)
        .then((res) => {
          snackbar.show({
            text: res.data,
            pos: "top-center",
            showAction: false,
            backgroundColor: snackbar.backgroundColor,
            duration: snackbar.duration,
            textColor: snackbar.colorLevel[2],
          });
          this.speakers = this.speakers.filter((s) => s._id != this.temp._id);
          this.reset_slct();
        })
        .catch((err) => {
          snackbar.show({
            text: err.response.data,
            pos: "top-center",
            showAction: false,
            backgroundColor: snackbar.backgroundColor,
            duration: snackbar.duration,
            textColor: snackbar.colorLevel[0],
          });
        });
    },
    reset_slct() {
      this.temp = {
        spk_type: 0,
        spk_firstname: "",
        spk_lastname: "",
        spk_email: "",
        spk_age: undefined,
        spk_gender: "",
        spk_lang: [],
        spk_thumbnail: undefined,
      };
      this.slct_value = "";
      this.file = undefined;
      this.collect_lang_default();
      this.data_updated = false;
      this.errors = {
        spk_firstname: {},
        spk_lastname: {},
        spk_email: {},

        spk_age: {},
        spk_gender: {},

        img: {},
        global: false,
      };
    },
    collect_lang_default() {
      let collect = ["Français", "Anglais", "Espagnol", "Allemand", "Latin"];
      this.collect_lang = collect;
    },
    change_image(file) {
      this.file = file;
      this.autoSave.lastKeyUp = new Date();
      this.autoSave.status = 0;
    },
    new_lang(event) {
      if (this.temp.spk_lang) {
        this.temp.spk_lang.push(event.target.value);
      } else {
        this.temp.spk_lang = [];
        this.temp.spk_lang[0] = event.target.value;
      }
      let index = this.collect_lang.indexOf(event.target.value);
      if (index > -1) {
        this.collect_lang.splice(index, 1);
      }
      event.target.value = "";
    },
    del_lang(lang) {
      let index = this.temp.spk_lang.indexOf(lang);
      if (index > -1) {
        this.temp.spk_lang.splice(index, 1);
      }

      this.collect_lang.push(lang);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./style.css" scoped></style>

<style scoped>
#collect {
  display: block;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  background-color: #e4d8b0;
  padding: 10px;
  padding-bottom: 5px;
  border-radius: 25px;

  font-size: 1.1vw;
}
#block_img {
  display: block;
  width: 15%;
  height: 100%;
  margin-left: 1vw;
}

#block_field {
  display: block;
  width: 45%;
  margin-left: 2%;
}
</style>


